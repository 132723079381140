
import { Component, Mixins } from 'vue-property-decorator';
import mixinNencho from '@/mixins/mixinNencho';
import CommonProgress from "@/components/common/Progress.vue";
import NenchoMasterNonencho from "@/components/nencho/master/Nonencho.vue";
import NenchoMasterMonthchanger from "@/components/nencho/master/Monthchanger.vue";
import NenchoMasterMain from "@/components/nencho/master/Main.vue";

@Component({
  components: {
    CommonProgress,
    NenchoMasterNonencho,
    NenchoMasterMonthchanger,
    NenchoMasterMain,
  }
})
export default class Nencho extends Mixins(mixinNencho) {

}
