
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import global from "@/vuex/nencho/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/nencho/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import NenchoMasterTable from "@/components/nencho/master/Table.vue";
  import NenchoMasterNonenchomaster from "@/components/nencho/master/Nonenchomaster.vue";

  @Component({
    components: {
      CommonProgress,
      NenchoMasterTable,
      NenchoMasterNonenchomaster,
    },
  })
  export default class Main extends Mixins(mixinNencho) {
    save = null;

    async created() {
      this.save = await this.m.getSaveMaster({
        nencho_id: this.nencho_id,
        employee_id: this.employee_id,
      });
      if (this.save !== null) {
        let kind = "";
        if (this.save.kind == "employee") {
          kind = "本人情報";
        } else if (this.save.kind == "insurance") {
          kind = "保険料等";
        } else if (this.save.kind == "prev_payment") {
          kind = "前職分の給与等";
        } else if (this.save.kind == "loan") {
          kind = "住宅ローン控除";
        }
        dialog.openOptionDialog({
          title: "確認",
          msg: `${kind}の途中保存データが見つかりました。<br>前回の続きから回答しますか？`,
          detail: "「いいえ」を選択すると途中保存のデータは削除されます。",
          cancel_button: "データを削除",
          exec_button: "回答する",
          func: this.startFromSave,
          cancel_func: this.deleteSave,
          args: {},
          cancel_args: {},
        });
      }
    }

    startFromSave({}) {
      if (this.save.kind == "employee") {
        this.go_to_nencho_employee();
      } else if (this.save.kind == "insurance") {
        this.go_to_nencho_insurance();
      } else if (this.save.kind == "prev_payment") {
        this.go_to_nencho_prev_payment();
      } else if (this.save.kind == "loan") {
        this.go_to_nencho_loan();
      }
    }
    async deleteSave({}) {
      await this.m.deleteSave({
        nencho_id: this.nencho_id,
        employee_id: this.employee_id,
        kind: this.save.kind,
      });
      this.kind = null;
    }

    go_to_nencho_employee() {
      this.change_page("nencho_employee", {
        nencho_id: this.nencho_id,
        is_finish_employee: undefined,
      });
    }
    go_to_nencho_insurance() {
      this.change_page("nencho_insurance", {
        nencho_id: this.nencho_id,
        is_finish_insurance: undefined,
      });
    }
    go_to_nencho_prev_payment() {
      this.change_page("nencho_prev_payment", {
        nencho_id: this.nencho_id,
        is_finish_prev_payment: undefined,
      });
    }
    go_to_nencho_loan() {
      this.change_page("nencho_loan", { nencho_id: this.nencho_id, is_finish_loan: undefined });
    }
  }
