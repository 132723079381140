
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/nencho/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import CommonBigbutton from "@/components/common/Bigbutton.vue";
import NenchoMasterDialogMaster from "@/components/nencho/master/dialog/Master.vue";

@Component({
  components: {
    CommonBigbutton,
    NenchoMasterDialogMaster,
  }
})
export default class Main extends Mixins(mixinNencho) {
  created() {
    if (this.is_valid_employee_yearly && this.nencho_master_employee.option_selected != 1) {
      console.log('open_nencho_dialog');
      this.m.setIsOpenNenchoMasterDialog(true);
    }
  }

  go_to_nencho_employee() {
    this.change_page('nencho_employee', {nencho_id: this.nencho_id, is_finish_employee: undefined});
  }
  go_to_nencho_insurance() {
    this.change_page('nencho_insurance', {nencho_id: this.nencho_id, is_finish_insurance: undefined});
  }
  go_to_nencho_prev_payment() {
    this.change_page('nencho_prev_payment', {nencho_id: this.nencho_id, is_finish_prev_payment: undefined});
  }
  go_to_nencho_loan() {
    this.change_page('nencho_loan', {nencho_id: this.nencho_id, is_finish_loan: undefined});
  }
}
