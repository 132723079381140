
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";

@Component({
  components: {
  }
})
export default class Control extends Mixins(mixinNencho) {

}
