
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinNencho from '@/mixins/mixinNencho';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonRadio from "@/components/common/Radio.vue";
import CommonCheck from "@/components/common/Check.vue";
import info_dialog from '@/vuex/info_dialog';

@Component({
  components: {
    UtilPopup,
    CommonRadio,
    CommonCheck,
  }
})
export default class Dialog extends Mixins(mixinNencho) {
  created() {
    this.tmp_nencho_master = {
      is_insurance: this.nencho_master_employee.is_insurance,
      is_prev_payment: this.nencho_master_employee.is_prev_payment,
      is_loan: this.nencho_master_employee.is_loan,
    }
  }

  cancel_func() {
    this.change_page('shift');
  }

  insurance_detail() {
    info_dialog.openDialog({title: '本年の保険料(控除の申告)', msg: '以下のいずれかの支払いがあればチェックを入れてください。<br><br>・生命保険料（一般、介護医療、個人年金）<br>・地震保険料<br>・給与から天引きされていない社会保険料<br>・給与から天引きされていない小規模企業共済掛金等'});
  }

  update_nencho_master_option() {
    this.m.updateNenchoMasterOption({nencho_id: this.nencho.id, employee_id: this.employee_id});
  }

  get tmp_nencho_master() {
    return this.m.nencho_master;
  }
  set tmp_nencho_master(value) {
    this.m.setNenchoMasterOption({nencho_master: value});
  }

  get is_display() {
    return this.m.is_open_nencho_master_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenNenchoMasterDialog(val);
  }
}
